import React from "react"

import Layout from "../layouts/layout"
import ImgBackground from "../components/imgBackground"
import Img from "gatsby-image"

import styled from "styled-components"

import { loremIpsum } from "lorem-ipsum";

export const query = graphql`
  query {
    file(relativePath: { eq: "avatar.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

const StyledImg = styled(Img)`
  width: 100%;

  border-radius: 50%;
`

const BioContainer = styled.div`
  margin: 1em;

  display: flex;
  align-items: center;
`

const BioText = styled.p`
  margin: 0 1em;

  color: white;
`

export default ({ data }) => (    
    <Layout>
        <div style={{
        height: `100vh`,
        width: `100%`,
        margin: `0 auto`,
        display: `block`,
        placeContent: `start`,
        }}>
            <ImgBackground>
                <BioContainer>
                    <StyledImg 
                        fluid={data.file.childImageSharp.fluid}
                    />
                    <BioText>
                        {
                            loremIpsum({
                                count: 5
                            })
                        }
                    </BioText>
                </BioContainer>
            </ImgBackground>
        </div>
    </Layout>
)